import React, { useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export default function VerifyEmail({ email }) {
    const [otp, setOtp] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Verify OTP 
    const sendOTP = async (e) => {
        setLoading(true)
        const data = {
            email: email,
            otp: otp
        };

        console.log(data);
        e.preventDefault();
        axios.post("https://api.sleepingwithwho.com/api/verify-email", data)
            .then(({ data }) => {

                localStorage.setItem('player_email', data.user.email);
                localStorage.setItem('user_id', data.user.id);
                localStorage.setItem('coins', data.coins);

                setLoading(false)

                window.location.reload()
            })
            .catch((error) => {
                console.log(error);
                const { response } = error;
                if (response.status === 400) {
                    alert("Invalid OTP")
                }
                setLoading(false)
            })
    }


    return (
        <>
            <div className="modal fade" id="verify-email" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-3 fw-bold text-danger" id="staticBackdropLabel">Oops!</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Oops, something went wrong! <br />
                                Please enter a valid email and try again.
                            </p>
                            {/* <form onSubmit={sendOTP}>
                                <div className="mb-3">
                                    <label htmlFor="otp" className="form-label">Enter OTP sent to your email</label>
                                    <input type="number" className="form-control" id="otp" placeholder="******" required
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                </div>
                                <button type="submit" className="btn btn-sultry w-100">
                                    {loading ? "processing..." :
                                        "submit"}
                                </button>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
