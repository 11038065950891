import "../css/WinModal.css"
import "../css/Modal.css"
import { Link } from "react-router-dom"
import { useEffect } from "react";
import JSConfetti from 'js-confetti'

const WinModal = (props) => {
    const canvas = document.querySelector("#confetti");
    const jsConfetti = new JSConfetti({ canvas });

    useEffect(() => {
        if (props.text === "Level Complete!") {
            jsConfetti.addConfetti();
        }
    }, [])


    if (props.text === "Try Again") {
        var show_hint = props.hint.current > 0 ? false : true;
    }

    return (
        <div className='back-drop'>
            <canvas id="confetti"></canvas>
            <div className='win-card'>
                <h2 className="fs-1">{props.text}</h2>
                {show_hint &&
                    <p className="p-2 text-center fw-bold" id="hint" style={color}>
                    {/* 💡 Share to continue 💡 */}
                    💡 Share game to get an extra move 💡
                    </p>
                }
                <span onClick={() => window.location.reload()}>
                    Restart
                </span>

                <span onClick={() => {
                    props.close();
                    props.hint.current = 0; //reset ref for hints to show later
                    console.log(props.hint);
                }}>
                    <Link to={'/scenes'} className="text-decoration-none text-white">
                        Back To Scenes
                    </Link>
                </span>

                <span className='share'>
                    <span>Share</span>
                    <span className='drop-content'>
                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            props.share();
                            let link = 'https://api.whatsapp.com/send?text=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-whatsapp text-success"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            props.share();
                            let link = 'https://telegram.me/share/url?url=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-telegram text-primary"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            props.share();
                            let link = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsleepingwithwho.com%2F&amp;src=sdkpreparse';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-facebook text-primary"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-3 fs-3' onClick={() => {
                            props.share();
                            let link = 'https://twitter.com/intent/tweet?url=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-twitter text-primary"></i>
                        </button>

                    </span>
                </span>
                <span>
                    <Link className='text-dark text-decoration-none' to={'/'} onClick={props.close}>
                        Quit
                    </Link>
                </span>
            </div>
        </div>
    )
}

const color = {
    textShadow: "0 0 3px #FF0000, 0 0 8px #0000FF",
    fontSize: "18px"
}
export default WinModal