import scene1 from '../assets/scene_1.jpg'

const SceneCard = ({ name, img }) => {
    return (
        <div>
            {/* <div className="card" style={{backgroundImage: `url("http://localhost:8000/storage/scenes/${img}")`,backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}> */}
            <div className="locked_card" style={{ backgroundImage: `url("https://api.sleepingwithwho.com/public/storage/scenes/${img}")`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                <div className="is_locked">
                    <i className="bi bi-lock fs-1" style={color}></i>
                </div>
            </div>
            <div><b><center>{name}</center></b></div>
        </div>
    )
}

// const style = {
//     backgroundImage: `url("${img}")`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center"
// }

const color = {
    color: '#F26959',
}

export default SceneCard