import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../components/BackButton";
import StoryCard from "../components/StoryCard";
import Loader from "../layout/Loader";
import axios from "axios";
import { Link } from "react-router-dom";

const Stories = (props) => {
  const [stories, setStories] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  useEffect(() => {
    async function getStories() {
      try {
        const res = await axios(`https://api.sleepingwithwho.com/api/stories`);
        setStories(res.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    getStories();

    // Retrieve the message from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get("message");

    if (message) {
      setAlertMessage(message);

      // Clear the alert message after 5 seconds
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
    }
  }, [location.search]);


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Perform search logic here
    console.log('Searching for:', searchTerm);
    props.search(searchTerm);
    setSearchTerm('');
  };

  if (!stories) {
    return <Loader />;
  }

  return (
    <div className="container mt-4">
      <h2 className="text-success fs-1">Sleeping  With Who Stories</h2>
      <p style={color}>Read some real life sleeping with who stories</p>

      <div>
        <div>
          <BackButton location={"/"} />
          <Link to="/stories/create" className="m-3 border border-2 px-2 py-1 rounded shadow">
            Write Your Story
          </Link>
        </div>

        <div className=''>
          <form onSubmit={handleSearchSubmit} className='d-flex'>
            <input
              type='text'
              name='search'
              className='form-control'
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder='Search...'
              style={{ width: '100%', maxWidth: '800px', border: '1px solid blue' }}
            />
            <button type='submit' className='btn btn-primary ms-2'>Search</button>
          </form>
        </div>
      </div>

      {/* Render the alert message */}
      {alertMessage && (
        <div className="alert alert-success" role="alert">{alertMessage}</div>
      )}

      <div className="story-list">
        {stories &&
          stories.map((story) => (
            <StoryCard key={story.id} story={story} />
          ))}
      </div>
    </div>
  );
};

const color = {
  color: '#E45A84'
}

export default Stories;
