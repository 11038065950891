import React, { useEffect, useState } from 'react'
import '../../css/Login.css'
import axios from 'axios';
import Loader from '../../layout/Loader.js'
// import { useNavigate} from 'react-router-dom'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState()

    useEffect(() => {
        console.log(errors);
    },[errors])

    const submitEmail = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        // await axios.post('http://localhost:8000/api/admin/login',{email,password})
        await axios.post('https://api.sleepingwithwho.com/api/admin/login',{email,password})
        .then((res)=>{
            localStorage.setItem('token', res.data.token,1 * 60 * 60 * 1000);
            console.log(res.data.token);
            setLoading(false)
            window.location = "/admin/dashboard"
        })
        .catch((errmsg)=>{
            console.log(errmsg.response.data.message);
            setErrors(errmsg.response.data.message);
            setLoading(false)
        })
    }

    return loading ? (<Loader />) :
        (
            <div className="container" id="login-form">

                {/* // Displaying errors */}
                {errors !== undefined &&<div className='text-danger'><i>{errors}</i></div>}

                <form onSubmit={submitEmail}>
                    <input type="email" name="email" id="email" placeholder="casanova@xyz.com"  value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <input type="password" name="password" id="password" placeholder='Password'  value={password} onChange={(e) => setPassword(e.target.value)} min ='6' required />

                    <button type="submit" className='btn btn-sultry'>
                        Log In
                    </button>
                </form>
            </div>
        );
}

export default Login;