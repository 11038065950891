import React, { useState } from 'react';
import '../css/Login.css';
import axios from 'axios';
import Loader from '../layout/Loader.js';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [email, setEmail] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleInput = (e) => {
    setEmail(e.target.value);
  };
      
  const recaptcha = (value) => {
    console.log("Captcha value:", value);
    setIsCaptchaVerified(true);
}

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios.post('https://api.sleepingwithwho.com/api/login', { email });

    let resStatus = res.data.status;

    if (resStatus === 200 || resStatus === 302) {
      localStorage.setItem('player_email', res.data.email);
      localStorage.setItem('user_id', res.data.id);
      localStorage.setItem('coins', res.data.coins);
      console.log(res.data);
      setLoading(false);

      window.location = "/scenes"
      console.log(location.state.from);
      console.log(res.data.message);
      console.log(res.data.email);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="container" id="login-form">
      <form onSubmit={submitEmail}>
        <input type="email" name="email" id="email" placeholder="casanova@xyz.com" onChange={handleInput} required />
        <ReCAPTCHA
            sitekey="6LfFZy0pAAAAAB_K0hBHiwo_r1TY39Xrajxunk-K"
            onChange={recaptcha}
        /> 
        <button type="submit" disabled={!isCaptchaVerified} className="btn btn-sultry">
          PLAY
        </button>
      </form>
    </div>
  );
};

export default Login;
