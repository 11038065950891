import React from 'react'

export default function Instruction() {
    return (
        <div className="modal fade" id="instructions" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog text-dark">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-3 fw-bold text-success" id="staticBackdropLabel">How to Play</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start">
                        <p>Here's how the game works</p>
                        <ul>
                            <li>
                                There are different scenes with characters that might be sleeping
                                with each other.
                            </li>
                            <li>Select an unlocked scene and you’ll be shown who is sleeping with who in that
                                scene.
                            </li>
                            <li>
                                Tap on the character silhouettes to flip them over and try to match the characters
                                that are sleeping with each other.
                            </li>
                            <li>
                                You have seven (7) moves to match the characters that are sleeping with each other.
                            </li>
                            <li>
                                If you run out of moves you’ll have to play that scene again.
                            </li>
                            <li>
                                When you complete a scene, the next scene would be unlocked and you’ll be
                                able to play it.
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sultry" data-bs-dismiss="modal">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Understood
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const color = {
    color: '#F26959',
}

