import '../css/StoryCard.css';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const StoryCard = ({ story }) => {
    return (
        <Link to={`/stories/${story.id}`}>
            <div className='p-2 my-3 story rounded shadow'>
                <small className='text-secondary fw-light'>Posted {dayjs(story.created_at).fromNow()}</small>

                <h3 className='m-0'>{story.title}</h3>
                <small className='fw-light'>{story.description.length > 30 ? `${story.description.substring(0, 30)}...` : story.description}</small>
            </div>
        </Link>
    )
}

export default StoryCard