import BackButton from '../components/BackButton'
import Button from '../components/Button'
import Loader from '../layout/Loader'
import CharModal from '../components/CharModal'
import Avatar from '../components/Avatar'

import '../css/Scene.css'
import thumb from '../assets/clicker.png'

import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Scene = () => {
    const [scene, setScene] = useState(null)
    const [characters, setCharacters] = useState([])
    const [selectedChar, setSelectedChar] = useState(null)

    const { id } = useParams();

    const config = {
        headers: {
            email: localStorage.getItem("player_email")
        }
    }

    useEffect(() => {
        async function getScene() {
            // await axios(`http://localhost:8000/api/${window.$userid}/scenes/${id}`).then((res) => {
            await axios(`https://api.sleepingwithwho.com/api/${window.$userid}/scenes/${id}`, config)
                .then((res) => {
                    setCharacters(res.data.characters)
                    setScene(res.data)
                });
        }
        getScene()
    }, [])

    if (!scene) {
        return <Loader />
    }

    const handleClick = (params) => {
        setSelectedChar(params)
    }

    const handleClose = () => {
        setSelectedChar(null)
    }

    setTimeout(() => document.getElementById('clicker').style.display = "none", 10000)
    return (
        <div className='container mt-4' id="scene">
            {/* Character pop modal */}
            {selectedChar && <CharModal info={selectedChar} close={handleClose} />}
            <div className='scene'>
                <h3 className='head-text text-success'>{scene.name}</h3>
                <BackButton location="/scenes" />
                <hr />

                <p style={style}>
                    {scene.description}
                </p>

                <div className='avatar-box'>
                    {/* hand clicker */}
                    <span className="fs-1" id="clicker"><img src={thumb} style={thumb_style} /></span>
                    {characters.map((char) => (
                        <div key={char.id} onClick={() => handleClick(char)}>
                            <Avatar char={char} />
                        </div>
                    ))}
                </div>
                <Button link='showcase' text='Next' />
            </div>

        </div>
    )
}

const style = {
    color: "#00008B",
    fontSize: "18px",
    fontWeight: "800"
}

const thumb_style = {
    width: "100px"
}

export default Scene