import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <>
            <div id="floating-nav">
                <Link to={"/stories/all"}>
                    <button>
                        <i className="bi bi-journal-bookmark-fill"></i> <span>Stories</span>
                    </button>
                </Link>
            </div>
        </>
    )
}
