import { Link } from "react-router-dom"

const BackButton = ({ location }) => {
    return (
        <Link to={location} className="text-decoration-none d-inline-block mb-3" style={btnStyle}>
            ⇦ Back
        </Link>
    )
}

const btnStyle = {
    padding: "2px 6px",
    borderRadius: "5px",
    width: "max-content",
    background: "#6842FF",
    color: "#fff",
}

export default BackButton