import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';

import Loader from '../layout/Loader';
import BackButton from "../components/BackButton";

const Story = () => {
    const [story, setStory] = useState();

    const { id } = useParams();

    useEffect(() => {
        async function getStory() {
            try {
                const response = await axios(`https://api.sleepingwithwho.com/api/stories/${id}`);
                setStory(response.data);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
        getStory();
    }, [id]);

    if (!story) {
        return <Loader />
    }

    console.log(story);

    var disqus_config = function () {
        this.page.url = 'https://www.sleepingwithwho.com';  // Replace PAGE_URL with your page's canonical URL variable
        this.page.identifier = '<?php echo get_permalink(); ?>'; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    };

    (function () { // DON'T EDIT BELOW THIS LINE
        var d = document, s = d.createElement('script');
        s.src = 'https://sleepingwithwho.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
    })();

    return (
        <div className="container">
            <div className="story-box scene">
                <BackButton location="/stories/all" />
                {/* SHARE BUTTON */}
                <div id="share" className="px-2 mx-2 popup fw-bold" onClick={() => document.getElementById("myPopup").classList.toggle("show")}>
                    <i class="bi bi-share"></i> Share this Story
                    <span className="popuptext d-flex justify-content-evenly" id="myPopup">
                        <i className="bi bi-whatsapp text-success fs-4" onClick={() => {
                            let link = 'https://api.whatsapp.com/send?text=';
                            let content = `Check out this game: https://www.sleepingwithwho.com/stories/${id}`;
                            window.open(link + encodeURIComponent(content));
                        }}></i>

                        <i className="bi bi-telegram text-primary fs-4" onClick={() => {
                            let link = 'https://telegram.me/share/url?url=';
                            let content = `Check out this game: https://www.sleepingwithwho.com/stories/${id}`;
                            window.open(link + encodeURIComponent(content));
                        }}></i>

                        <i className="bi bi-facebook text-primary fs-4" onClick={() => {
                            let link = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsleepingwithwho.com%2F&amp;src=sdkpreparse';
                            let content = `Check out this game: https://www.sleepingwithwho.com/stories/${id}`;
                            window.open(link + encodeURIComponent(content));
                        }}></i>

                        <i className="bi bi-twitter text-primary fs-4" onClick={() => {
                            let link = 'https://twitter.com/intent/tweet?url=';
                            let content = `Check out this game: https://www.sleepingwithwho.com/stories/${id}`;
                            window.open(link + encodeURIComponent(content));
                        }}></i>
                    </span>
                </div>
                <div className="mb-4">
                    <h2 className="fs-1">{story[0].title} </h2>
                    <p>
                        <span className="fw-bold">Description: </span>
                        <br /> <i>{story[0].description}</i>
                    </p>
                </div>
                <hr />
                <p dangerouslySetInnerHTML={{ __html: story[0]?.story }}></p>
            </div>
            <div id="disqus_thread"></div>
        </div>
    );
}

export default Story;
