import axios from 'axios'
import React, { useState } from 'react'
import '../../css/createForm.css'
import Loader from "../../layout/Loader";

const SceneForm = ({ onAdd }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [bg_image, setBg_image] = useState('')
    const [loader, setLoader] = useState(false)

    function onSubmit(e) {
        e.preventDefault()
        if (!name || !description || !bg_image) {
            alert('Fill out all fields')
        } else {
            setLoader(true)
            const res = axios.post('https://api.sleepingwithwho.com/api/admin/dashboard/scenes', {
                // const res = axios.post('http://localhost:8000/api/admin/dashboard/scenes', {
                name, description, bg_image
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
            )
                .then(response => {
                    var id = response.data.scene_id.original;

                    if (response.status === 200) {
                        alert('created successfully')
                        window.location = "/admin/dashboard/" + id
                        // alert(id);
                    }
                    setLoader(false)
                })
                .catch(error => {
                    alert('error in creating scene')
                    console.log(error);
                    setLoader(false)
                });
        }
    }


    return (
        <div className='container dashboard mt-4 p-3'>
            {loader ? <Loader /> :
                <form id='scene-form' style={{ display: 'block' }} onSubmit={onSubmit} encType='multipart/form-data' method="post">
                    <h4>Create a Scene</h4>
                    <input type="text" name="scene_name" placeholder='LashVille High' className='form-control my-3' onChange={(e) => setName(e.target.value)} required />
                    <textarea type="text" name="scene_description"
                        placeholder='LashVille HIgh is located in the alpine hill tops of the Poosi county' className='form-control my-3' onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                    <input type="file" className="form-control" name="char_img" onChange={(e) => setBg_image(e.target.files[0])} required />

                    <br />
                    <input type='submit' value='Add Scene' className='btn btn-primary' />
                </form>}

        </div>
    )
}

const imgStyle = {
    objectFit: "cover",
    maxWidth: "100%",
    borderRadius: "100%"
}

export default SceneForm