import { Link, useLocation } from 'react-router-dom';
import Coin from '../components/Coin';
import React from 'react'

const Header = (props) => {
    const location = useLocation();

    return (
        <header style={header}>
            <div className="container py-1 d-flex justify-content-between align-items-center text-white" id="nav-bar">
                <h1>
                    <img src={require(`../assets/logo.png`)} style={logo} alt="logo" />
                    <Link to="/" className='text-decoration-none fw-bold mx-2' style={{ color: "#6842FF" }}>
                        sleeping with who<sup><i className="bi bi-r-circle" style={tm}></i></sup>
                    </Link>
                </h1>
                <nav>
                    {/* Play Btn */}
                    {
                        location.pathname === '/scenes' && <button className="btn btn-sultry" title='About Us' data-bs-toggle="modal" data-bs-target="#AboutSww" type="button">
                            <i className="bi bi-person-video3" style={color}></i> <span className='play'></span>
                        </button>
                    }

                    {/* Contact Btn */}
                    {
                        location.pathname === '/scenes' && <button className="btn btn-sultry mx-2" title='Contact Us' data-bs-toggle="modal" data-bs-target="#contactUs" type="button">
                            <i className="bi bi-telephone-fill" style={color}></i>
                            <span className='Contact Us'></span>
                        </button>
                    }

                    {/* Shop Btn */}
                    <button className="btn btn-sultry" title='Go To Shop' type="button" onClick={() => window.location.href = "https://store.twofailures.com/products/sleeping-with-who-t-shirt"}>
                        <i class="bi bi-bag-fill" style={color}></i>
                        <span className='Contact Us'></span>
                    </button>

                    {location.pathname !== '/scenes' && localStorage.getItem("coins") ?
                        (<Coin coins={props.coins} />)
                        : ""}

                </nav>
            </div>
        </header>
    )
}

export default Header

const header = {
    background: 'background: rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(20px)',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: '10',
    position: 'sticky',
    top: '0px'
}
const color = {
    color: '#FFC1C4',
}

const logo = {
    position: "relative",
    top: "5px",
    transform: "scale(1.5, 1.5)",
    width: "40px"
}

const tm = {
    fontSize: "10px"
}