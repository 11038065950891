import React, { useState } from 'react';
import BackButton from "../components/BackButton";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

function CreateStory() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [story, setStory] = useState('');
  const [error, setError] = useState('');

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleEditorChange = (value) => {
    setStory(value);
  };


  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // text formatting options
    [{ align: [] }], // text alignment options
    // ['link', 'image', 'video'], // other options
  ];

  const handleSubmit = () => {
    const postData = {
      title: title,
      description: description,
      story: story,
    };

    axios.post('https://api.sleepingwithwho.com/api/stories', postData)
      .then((response) => {
        // Handle successful post request
        console.log(response.data);
        setError('');
        window.location = "/stories/all?message=Story created successfully"
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        setError('An error occurred. Please try again later.');
      });
  };

  return (
    <div className='container'>
      <div className='m-3'>
        <h1>Create Your story</h1>
        <BackButton location={"/stories/all"} />
      </div>
      {error && <div>Error: {error}</div>}

      <div>
        <div className='m-3'>
          <label>Title </label><br />
          <input type="text" value={title} onChange={handleTitleChange} style={{ width: '100%' }} required maxLength="100" /><br />

          <br />
          <label>Description </label><br />
          <input type="text" value={description} onChange={handleDescriptionChange} style={{ width: '100%' }} required maxLength="200" /><br />

          <br />
          <label>Story </label><br />
          <ReactQuill theme="snow" value={story} onChange={handleEditorChange} modules={{ toolbar: toolbarOptions }} style={{ height: 200 }} /><br />

          <br />
          <br />
          <button onClick={handleSubmit} className='m-2'>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default CreateStory;
