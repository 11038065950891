import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../css/Dashboard.css'
import AllScenes from './AllScenes'
import SceneForm from './SceneForm'
import axios from 'axios';

const Dashboard = () => {
    const [showForm, setShowForm] = useState(false)
    const [nav, setNav] = useState()
    const [scenes, setScenes] = useState(false)

    // toggle create scene form
    function toggleForm(nav) {
        setShowForm(nav)
        if (nav === 'allScenes') {
            getScenes()
        }
        console.log(showForm);
    }

    const getScenes = () => {
        // get all scenes
        const res = axios.get('https://api.sleepingwithwho.com/api/admin/dashboard/scenes', {
            // const res = axios.get('http://localhost:8000/api/admin/dashboard/scenes', {
            // method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((res) => {
                setScenes(res)
            })
        console.log(scenes);
        // setScenes(res.data)
    }

    const del = (id) => {
        console.log(id);
        // axios.delete(`http://localhost:8000/api/admin/dashboard/scenes/${id}`, {
        axios.delete(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}`, {
            // method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((res) => {
                console.log(res);
                getScenes()
            })
            .catch((err) => {
                console.log(err)
            })
        console.log(scenes);
    }

    const enable = (id) => {
        console.log(id);
        axios.get(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}/enable`, {
            // axios.delete(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}`, {
            // method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((res) => {
                console.log(res);
                getScenes()
            })
            .catch((err) => {
                console.log(err)
            })
        console.log(scenes);
    }

    const edit = (id) => {
        window.location = "/admin/dashboard/" + id
    }

    return (
        <>
            <div className="container dashboard">
                <div className="dashboard-cards">

                    <div className="d-card">
                        <h3>
                            Scenes
                        </h3>
                        <p>
                            <Link className='text-decoration-none text-dark'>
                                <button style={btnStyle2} onClick={() => toggleForm('allScenes')}>
                                    All Scenes: {scenes.length}
                                </button>
                            </Link>
                            <br />
                            <Link className='text-decoration-none text-dark'>
                                <button style={btnStyle2} >
                                    Live Scenes: {7}
                                </button>
                            </Link>
                            <br />
                            <Link to='/admin/stories' className='text-decoration-none text-dark'>
                                <button style={btnStyle2}>
                                    All Stories: {10}
                                </button>
                            </Link>
                            <br />
                            <Link to='/admin/pending_stories' className='text-decoration-none text-dark'>
                                <button style={btnStyle2}>
                                    All Pending Stories: {10}
                                </button>
                            </Link>
                            <br />
                        </p>
                    </div>

                    <div className="d-card">
                        <h3>
                            Users
                        </h3>
                        <p>
                            All Users: {"1,234,353"}
                            <br />
                            Verified Scenes: {"935,001"}
                        </p>
                    </div>

                    <div className="d-card">
                        <h3>
                            Settings
                        </h3>
                        <p>
                            <Link className='text-decoration-none text-dark w-100 d-inline-block'>
                                <button style={btnStyle} className='w-100' onClick={() => toggleForm('newScene')} >
                                    <i className="bi bi-plus-circle-dotted fw-bold" ></i> Add New Scene
                                </button>
                            </Link>

                            <Link className='text-decoration-none text-dark w-100 d-inline-block' to="/admin/stories/create">
                                <button style={btnStyle} className='w-100'>
                                    <i class="bi bi-pencil-square"></i> Create Story
                                </button>
                            </Link>

                            <br />

                            <Link className='text-decoration-none text-dark d-inline-block w-100 '>
                                <button style={btnStyle} className="text-left w-100 ">
                                    <i class="bi bi-box-arrow-right"></i> Sign Out
                                </button>
                            </Link>
                        </p>
                    </div>

                </div>
            </div>

            {/* Add scene component */}
            {/* {showForm === 'allScenes' && <AllScenes /> } */}
            {showForm === 'allScenes' &&
                <div className='container dashboard mt-4 p-3'>
                    <h4>All Scenes</h4>
                    {/* <ul className="list-group">
                        {scenes.map((scene)=><li className="list-group-item d-flex justify-content-between align-items-center">
                            {scene.name}
                            <div><button type="button" className="btn btn-outline-primary"  onClick={()=>{edit(scene.id)}}>Edit</button>
                            <button type="button" className="btn btn-outline-danger" onClick={()=>{del(scene.id)}}>Disable</button></div>
                        </li>)}
                    </ul> */}
                </div>
            }
            {showForm === 'newScene' && <SceneForm />}

        </>
    )
}

const btnStyle = {
    background: 'white',
    border: 'none',
    borderRadius: '5px',
    marginBottom: '5px'
}
const btnStyle2 = {
    background: 'transparent',
    border: 'none',
    color: 'blue',
}
export default Dashboard