export default function AboutSww() {
  return (
    <div>
      <div className="modal fade" id="AboutSww" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog text-dark">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3 fw-bold text-success" id="staticBackdropLabel">Sleeping With Who</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-start">
              <p>
                <b>“Sleeping With Who”</b> is a game born out of the experience of its creator who while working at a police department,
                witnessed his colleagues have numerous affairs with each other. <br /> <br />
                After he was passed for promotion for a less qualified co-worker who was sleeping with their supervisor, he was forced out after he complained.
              </p>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-sultry" data-bs-dismiss="modal">
                <i className="bi bi-hand-thumbs-up-fill"></i> Close
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
