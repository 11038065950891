import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';

import BackButton from "../components/BackButton";
import Loader from "../layout/Loader";
import Avatar from "../components/Avatar";
import Pause from "../components/Pause";
import Message from "../components/Message"
import '../css/Flipcard.css'

import "../css/Showcase.css";
import Instruction from "../components/Instruction";

function Showcase(props) {
  const { id } = useParams();
  const [scene, setScene] = useState();
  const [lashers, setLashers] = useState([])
  const [allLashers, setAllLashers] = useState([])
  const [moves, setMoves] = useState(7);
  const [win, setWin] = useState(0)
  const [coins, setCoins] = useState(window.$coins)
  const [share, setShare] = useState(0)
  const [shuffleLashers, setShuffleLashers] = useState(false)
  const [newUser, setNewUser] = useState(true)

  var userClicksContents = [], eventIds = [], singleClickId = [], userClicksIds = [];

  useEffect(() => {
    // ALL LASHERS IN PAIR
    var data = props.algo(id);

    //CONVERT PROMISE TO ARRAY
    data.then(result => {
      const myArray = Array.isArray(result) ? result : [result];
      let lash = myArray[0]
      setScene(myArray[1])
      setLashers(myArray[0]);

      // ALL LASHER
      const allLash = lash[0].concat(lash[1], lash[2], lash[3], lash[4], lash[5]);
      setAllLashers(allLash)

      function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
      }
      setShuffleLashers(shuffle(allLash));
    })

  }, [])

  // ONCLICK FUNCTION
  const handleClick = (e, char) => {
    e.preventDefault();

    if (moves <= 0 && win !== lashers.length) {
      // alert("You're out of moves");
      savecoin()
      props.winOrlose(true, "Out of moves, Try Again", false)
    } else {
      let event = e.currentTarget.id;
      let event2 = e.target.parentNode.id;

      const openEvent = (id) => {
        if (document.getElementById(id).getAttribute('style') === null || document.getElementById(id).getAttribute('style') === '') {
          return true
        }
      }

      eventIds = [event, event2];
      // if (userClicksContents[0] != char && openEvent(event) && openEvent(event2)) {
      //   userClicksContents.push(char);
      if (userClicksIds[0] !== event && openEvent(event) && openEvent(event2)) {
        userClicksIds.push(event);
        userClicksContents.push(char);
        singleClickId.push(event, event2);

        //Flipping the card
        flipOpen(eventIds);
      }

      // if (userClicksContents.length === 2) {
      if (userClicksIds.length === 2) {
        setMoves(moves - 1);
        if (pairsFind(userClicksContents, lashers)) {
          let msg = document.getElementById("game-message");

          msg.style.display = "inline-block";
          msg.innerHTML = "🎉 Correct 🎉";
          setTimeout(() => {
            msg.style.display = "none"
          }, 1500)

          //REMOVES ELEMENT FROM LASHERS1 ARRAY
          setWin(win + 1)

          //ADD COINS
          addcoins();
          singleClickId = [];
        } else {
          singleClickId.map((id) => {
            let element = document.getElementById(id);
            setTimeout(() => {
              element.removeAttribute('style');
            }, 500)
          });

          // Game message
          let msg = document.getElementById("game-message");
          msg.style.display = "inline-block";
          msg.innerHTML = "🤪Nope🤪";
          setTimeout(() => {
            msg.style.display = "none";
          }, 1500)

          singleClickId = [];
          // props.winOrlose(true, "Try Again", false)
          // isShared();
        }
      }

      // FUNCTION TO CHECK IF PAIRS EXISTS
      function pairsFind(arr1, arr2) {
        if (arr1[0] === arr1[1]) {
          return false
        }
        for (let i = 0; i < arr2.length; i++) {
          if (arr2[i].includes(arr1[0])) {
            if (arr2[i].includes(arr1[1])) {
              return true
            }
          }
        }
        return false;
      }
    }
  };

  const flipOpen = (ids) => {
    ids.map((id) => {
      let element = document.getElementById(id);
      element.style.transform = "rotateY(180deg)";
    });
  };

  function addcoins() {
    let coin = parseInt(coins) + 5;
    localStorage.setItem("coins", coin);
    setCoins(coin)
    console.log(coins);
    props.func(coin)
  }

  function savecoin() {
    var data = {
      coins: localStorage.getItem('coins')
    }
    axios.post(`https://api.sleepingwithwho.com/api/${window.$userid}/scenes/addcoin`, data)
  }

  function playbtn() {
    document.getElementById("playcomponent").style.display = "block"
    if (parseInt(coins) === 0 && newUser) {

      // POP UP INSTRUCTION MODAL
      const instruction = document.getElementById('instruction');
      console.log(instruction);
      instruction.setAttribute('data-bs-toggle', 'modal');
      instruction.setAttribute('data-bs-target', '#staticBackdrop');
      instruction.click()

      setNewUser(false)
    }
  }


  //NEW FEATURE FOR POPING TRY AGAIN FOR TWO MISMATCH PAIR
  function isShared() {
    if (share === 0) {
      setShare(share + 1)
      setMoves(moves + 1)
      props.winOrlose(true, "Try Again", false)
    } else {
      savecoin()
      props.winOrlose(true, "Try Again", true)
    }

  }

  // UNLOCKING NEXT SCENE IF USER WIN
  if (lashers.length !== 0) {
    if (win === lashers.length) {
      var sceneId = parseInt(id) + 1
      var data = {
        scene_id: sceneId,
        coins: localStorage.getItem('coins')
      }

      props.winOrlose(true, "Level Complete!", true)
      axios.post(`https://api.sleepingwithwho.com/api/${window.$userid}/scenes/unlock_scene`, data)
    }
  }


  useEffect(() => {
    // SEt THE MOVES TO 5 SO THE USER WILL BE STOPPED WHEN THEY FAIL TWICE
    if (moves <= 5 && win !== lashers.length) {
      console.log(lashers.length);
      if (share === 0 && props.addMoves) {
        console.log(props.addMoves);
        setShare(share + 1)
        setMoves(moves + 1)
      } else {
        savecoin()
        setTimeout(() => {
          props.winOrlose(true, "Try Again", false)
        }, 800);
      }
    }
  }, [id, props.addMoves, moves])

  return scene ? (
    <>
      <div className="container mt-4">
        <button id="instruction" style={{ display: 'none' }} type="button"></button>
        <div className="scene-play">
          <h2 className="text-success fs-2">{scene.name}</h2>
          <BackButton location={`/scene/${id}`} />
          <p className="text-success fw-bold">
            Who is currently sleeping with who
          </p>

          <div id="pairs">
            {
              //Displaying the matched pairs
              lashers.map((sub, index) => (
                <div className="m-2 rounded" key={index}>
                  {sub.map((obj) => (
                    <Avatar key={obj.id} char={obj} style={{ border: "border: 3px solid #ff6c71" }} />
                  ))}
                </div>
              ))
            }
          </div>

          <a href="#play" className="text-decoration-none">
            {/* <button className="btn btn-sultry my-4 d-block" onClick={() => document.getElementById("playcomponent").style.display = "block"}>
              <i className="bi bi-play fw-bold"></i>Play
            </button> */}
            <button className="btn btn-sultry my-4 d-block" onClick={playbtn}>
              <i className="bi bi-play fw-bold"></i>Play
            </button>
          </a>

          <hr />
          <div className="mt-1 mx-auto" id="playcomponent" style={{ display: "none" }}>
            <div className="my-3">

              <div className="px-3 py-1 play-btn"><i className="bi bi-hand-index-thumb"></i> Moves: {moves}</div>

              <div className="px-3 py-1 mx-2 play-btn popup" onClick={() => document.getElementById("myPopup").classList.toggle("show")}>
                Share
                <span className="popuptext d-flex justify-content-evenly" id="myPopup">
                  <i className="bi bi-whatsapp text-success fs-4" onClick={() => {
                    let link = 'https://api.whatsapp.com/send?text=';
                    let content = 'Check out this game: https://www.sleepingwithwho.com';
                    window.open(link + encodeURIComponent(content));
                  }}></i>

                  <i className="bi bi-telegram text-primary fs-4" onClick={() => {
                    let link = 'https://telegram.me/share/url?url=';
                    let content = 'Check out this game: https://www.sleepingwithwho.com';
                    window.open(link + encodeURIComponent(content));
                  }}></i>

                  <i className="bi bi-facebook text-primary fs-4" onClick={() => {
                    let link = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsleepingwithwho.com%2F&amp;src=sdkpreparse';
                    let content = 'Check out this game: https://www.sleepingwithwho.com';
                    window.open(link + encodeURIComponent(content));
                  }} ></i>

                  <i className="bi bi-twitter text-primary fs-4" onClick={() => {
                    let link = 'https://twitter.com/intent/tweet?url=';
                    let content = 'Check out this game: https://www.sleepingwithwho.com';
                    window.open(link + encodeURIComponent(content));
                  }}></i>
                </span>
              </div>

              <Pause pp={props.pause} />

            </div>
            <p className="text-success fw-bold">Match who is sleeping with who</p>


            {/* Flip Cards */}
            <div className="mx-auto align d-flex justify-content-evenly flex-wrap" id="play">

              {shuffleLashers.map((char, index) => (
                <div key={index} id={index} className="flipcard" onClick={(e) => { handleClick(e, char); }}>
                  <div id={`key${index}`} className="flip-inner" >
                    <div className="flip-back">
                      <img alt="..." src={`https://api.sleepingwithwho.com/public/storage/characters/${char.img}`} />
                    </div>
                    <div className="flip-front">
                      {/* <img alt="..." style={imgStyle} src={require(`../assets/null_user.jpg`)} /> */}
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <Message />
          </div>
          {/* Flip Cards End */}

        </div>
      </div>
      <Instruction />
    </>
  ) : (<Loader />);
}

export default Showcase
