import '../css/StoryCard.css';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';
import axios from 'axios';

dayjs.extend(relativeTime);

const AdminStoryCard = ({ story }) => {

  const handleDeleteStory = async (id) => {
    try {
      const res = await axios.delete(`https://api.sleepingwithwho.com/api/admin/stories/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      // Handle successful response
      console.log(res.data);
      window.location = "/admin/stories"
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  return (
    <div className='p-2 my-3 story rounded shadow'>
      <Link to={`/stories/${story.id}`}>
        <small className='text-secondary fw-light'>Posted {dayjs(story.created_at).fromNow()}</small>

        <h3 className='m-0'>{story.title}</h3>
        <small className='fw-light'>
          {story.description.length > 30 ? `${story.description.substring(0, 30)}...` : story.description}
        </small>
      </Link>

      <div className='text-left'>
        <a href='#' className='mr-2 text-danger' onClick={() => handleDeleteStory(story.id)}>
          <i class="bi bi-x-circle-fill"></i> Delete Story
        </a>
      </div>
    </div>
  );
};

export default AdminStoryCard;
