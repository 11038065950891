import { Link } from 'react-router-dom'

const ContactUs = () => {
    return (
        <>
            <div className="modal fade" id="contactUs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="aboutLabel" aria-hidden="true">
                <div className="modal-dialog text-dark">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-3 fw-bold" id="aboutLabel" style={color}>
                                Sleeping With Who
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-start">
                            For advert inquiries send e-mail to <br /> <Link to={"#"}>business@sleepingwithwho.com</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const color = {
    color: "#6842FF",
}

export default ContactUs