// import '../css/Modal.css'
import '../css/CharModal.css'

const CharModal = ({ info, close }) => {
    return (
        <>
            <div className="char-card" onClick={close}>
                {/* <img src={require(`../assets/char_4.png`)} className="card-img-top" alt="..." /> */}
                <img src={`https://api.sleepingwithwho.com/public/storage/characters/${info.full_img}`} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h4 className="fw-bold" style={color}>{info.name}</h4>
                    <p className="card-text">{info.about}</p>
                    <button onClick={close} className="btn">
                        <i className="bi bi-x-circle fs-1"></i>
                    </button>
                </div>
            </div>
        </>
    )
}

const color = {
    color: "#6842FF"
}
export default CharModal