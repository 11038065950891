import Home from './Home.js';
import Login from './Login.js';
import Stories from './Stories.js'
import Story from './Story.js';
import Scenes from './Scenes.js';
import Scene from './Scene.js';
import Showcase from './Showcase.js';
import PageNotFound from './PageNotFound.js';
import CreateStory from './CreateStory.js';
import AdminStory from './AdminStory.js';
import SearchStories from './SearchStories.js';

const Pages = {
    AdminStory,
    CreateStory,
    Home,
    Login,
    Stories,
    Story,
    Scenes,
    Scene,
    Showcase,
    PageNotFound,
    SearchStories
}

export default Pages;