import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../components/BackButton";
import StoryCard from "../components/StoryCard";
import Loader from "../layout/Loader";
import axios from "axios";
import { Link } from "react-router-dom";

const SearchStories = (props) => {
  console.log(props.searchresults);
  const [stories, setStories] = useState(props.searchresults);
  useEffect(() => {
    setStories(props.searchresults)
  }, [props.searchresults])

  
  return (
    <>
      {stories ? (
        <div className="container mt-4">
          <h2 className="text-success">Stories</h2>
          <p>Tap into some juicy wet stories from real-life situations</p>
          <BackButton location={"/stories/all"} />
          <Link to="/stories/create" className="m-3">
            Create story
          </Link>
  
          <div className="story-list">
            {stories.map((story) => (
              <StoryCard key={story.id} story={story} />
            ))}
          </div>
        </div>
      ) : (
        <div>NO STORY MATCH FOR THE SEARCH</div>
      )}
    </>
  );
  
}
  
export default SearchStories;
