
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';

import Loader from '../layout/Loader';

const AdminStory = () => {
    const [story, setStory] = useState();

    const { id } = useParams();

    useEffect(() => {
        async function getStory() {
            try {
                await axios(`https://api.sleepingwithwho.com/api/admin/unapproved/stories/${id}`, {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }).then((res) => {
                    setStory(res.data);
                })
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        getStory();
    }, [])

    if (!story) {
        return <Loader />
    }

    console.log(story);
    return (
        <div className="container">
            <div className="story-box scene">
                <h2 className="fs-1">{story[0].title} </h2>
                <p className="border rounded d-inline-block p-2 shadow bg-secondary">{story[0].description}</p>
                <p dangerouslySetInnerHTML={{ __html: story[0]?.story }}></p>


            </div>
        </div>
    )
}

export default AdminStory