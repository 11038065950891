import { Link } from 'react-router-dom'
import '../css/Modal.css'

function confirmation(e) {
    if (e.currentTarget.innerHTML === "Restart") {
        if (window.confirm('Are you sure you want to proceed?')) {
            window.location.reload();
        }
    }
    if (e.currentTarget.innerHTML === "Quit") {
        if (window.confirm('Are you sure you want to proceed?')) {
            window.location.href = "/";
        }
    }
    // console.log(e.currentTarget.innerHTML);
}
const Modal = ({ resume }) => {

    return (
        <div className='back-drop'>
            <img src={require('../assets/logo.png')} alt='logo' />
            <div className='pause-card'>
                <span onClick={resume}>
                    Resume
                </span>
                <span onClick={(e) => confirmation(e)}>
                    Restart
                </span>
                <span className='share'>
                    <span>Share</span>
                    <span className='drop-content'>
                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            let link = 'https://api.whatsapp.com/send?text=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-whatsapp text-success"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            let link = 'https://telegram.me/share/url?url=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-telegram text-primary"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-4 fs-3' onClick={() => {
                            let link = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsleepingwithwho.com%2F&amp;src=sdkpreparse';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-facebook text-primary"></i>
                        </button>

                        <button className='fs-6 text-dark text-decoration-none mx-3 fs-3' onClick={() => {
                            let link = 'https://twitter.com/intent/tweet?url=';
                            let content = 'Check out this game: https://www.sleepingwithwho.com';
                            window.open(link + encodeURIComponent(content));
                        }}>
                            <i className="bi bi-twitter text-primary"></i>
                        </button>
                    </span>
                </span>
                <span onClick={(e) => confirmation(e)}>
                    Quit
                </span>
            </div>
        </div>
    )
}

export default Modal