import { useEffect, useState } from "react";
import BackButton from "../../../components/BackButton";
import AdminPendingStoryCard from "../../../components/AdminPendingStoryCard";
import Loader from "../../../layout/Loader";
import axios from "axios";
import { Link } from "react-router-dom";

const PendingStories = () => {

    const [stories, setStories] = useState(null);

    useEffect(() => {
        async function getStories() {
            try {
                const res = await axios(`https://api.sleepingwithwho.com/api/admin/unapproved/stories`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                console.log(res.data);
                setStories(res.data)
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        getStories();
    }, []);

    if (!stories) {
        return <Loader />
    }

    return (
        <div className='container mt-4'>
            <h2 className='text-success'>Stories</h2>
            <p>Unapproved Stories will appear here</p>
            <BackButton location={"/admin/dashboard"} />
            <Link to="/stories/create" className="m-3">Create story</Link>

            <div className="story-list">
                {
                    stories > 0 ? stories.map((story) => <AdminPendingStoryCard key={story.id} story={story} />) : <p className="text-light bg-secondary px-2">No pending stories</p>
                }
            </div>
        </div>
    )
}

export default PendingStories