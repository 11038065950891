import '../css/Avatar.css'

const Avatar = ({ char }) => {
    return (
        <>
            <div className='avatar'>
                {/* <img alt="..." src={require(`../assets/characters/${char.img}.png`)} title={char.name} /> */}
                {/* <img alt="..." src={`http://localhost:8000/storage/characters/${char.img}`} title={char.name} /> */}
                <img alt="..." src={`https://api.sleepingwithwho.com/public/storage/characters/${char.img}`} title={char.name} />
            </div>
        </>
    )
}

export default Avatar