const Loader = () => {
    setTimeout(function () {
        document.getElementById("loader").innerHTML = "Tap here to reload 🥵";
    }, 720000);

    return (
        <div style={loader} className="container" id="loader" onClick={() => window.location.reload()} >
            <div className="spinner-border text-dark text-center" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

const loader = {
    margin: '0 auto',
    marginTop: '10%',
    width: "max-content",
    padding: "10px 20px",
    borderRadius: "1rem 1rem 1rem 1rem",
    background: "rgba(255, 255, 255, 0.2)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(5px)",
    cursor: "pointer"
}

export default Loader;