import React from 'react'
import '../css/App.css'

const Footer = () => {
    return (
        <footer className='text-center mt-5' style={style}>
            <h4>
                Follow Us On
            </h4>
            <div className='text-center'>
                <i className='fs-2 mx-2 bi bi-facebook' onClick={() => {
                    window.open('https://www.facebook.com/groups/sleepingwithwho/');
                }}></i>
                <i className='fs-2 mx-2 bi bi-telegram' onClick={() => {
                    window.open('https://t.me/sleepingwithwho');
                }}></i>
                <i className='fs-2 mx-2 bi bi-twitter' onClick={() => {
                    window.open('https://twitter.com/sleepingwitwho');
                }}></i>
            </div>
        </footer>
    )
}

const style = {
    color: "#6842FF",
}

export default Footer