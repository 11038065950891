import '../css/StoryCard.css';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';
import axios from 'axios';

dayjs.extend(relativeTime);

const AdminPendingStoryCard = ({ story }) => {

    const handleDeleteStory = async (id) => {
        try {
          const res = await axios.delete(`https://api.sleepingwithwho.com/api/admin/stories/${id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          });
          // Handle successful response
          console.log(res.data);
          window.location='/admin/pending_stories'
        } catch (error) {
          // Handle error
          console.error(error);
        }
      };

      const handleApproveStory = async (id) => {
        try {
          const res = await axios.post(
            `https://api.sleepingwithwho.com/api/admin/stories/${id}/approve`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          );
          // Handle successful response
          console.log(res.data);
          window.location = '/admin/pending_stories'
        } catch (error) {
          // Handle error
          console.error(error);
        }
      };
      

  return (
    <div className='p-2 my-3 story rounded shadow'>
      <Link to={`/admin/stories/unapproved/${story.id}`}>
        <small className='text-secondary fw-light'>{dayjs(story.created_at).fromNow()}</small>

        <h3 className='m-0'>{story.title}</h3>
        <small className='fw-light'>
          {story.description.length > 30 ? `${story.description.substring(0, 30)}...` : story.description}
        </small>
      </Link>

      <div className='text-left'>
        <Link to={`/admin/edit/story/${story.id}`} className='m-2 text-danger'>
          Edit Story
        </Link>
        <a href='#' className='m-2 text-danger' onClick={() => handleApproveStory(story.id)}>
          Approve Story
        </a><a href='#' className='m-2 text-danger' onClick={() => handleDeleteStory(story.id)}>
          Delete Story
        </a>
      </div>
    </div>
  );
};

export default AdminPendingStoryCard;
