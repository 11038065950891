import React, { useState, useRef, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./css/App.css";

import AddChar from "./pages/Admin/AddChar";
import Pages from "./pages/index.js";
import Dashboard from "./pages/Admin/Dashboard";
import Login from "./pages/Admin/Login";
import AllStories from "./pages/Admin/stories/AllStories";
import PendingStories from "./pages/Admin/stories/PendingStories";

import Layout from "./layout/index.js";
import Modal from "./components/Modal";
import WinModal from "./components/WinModal";
import AdminCreateStory from "./pages/Admin/stories/AdminCreateStory";
import AdminEditStory from "./pages/Admin/stories/AdminEditStory";

window.$userid = localStorage.getItem('user_id')
window.$coins = localStorage.getItem('coins')

function App() {

  const [pause, setPause] = useState(false);
  const [win, setWin] = useState(false);
  const [winText, setWinText] = useState("");
  const [coins, setCoins] = useState(window.$coins)
  const [addMoves, setAddMoves] = useState(false)
  const [enableAddMove, setEnableAddMove] = useState(true)
  const [searchResults, setSearchResults] = useState()

  let ref = useRef(0);
  const navigate = useNavigate();

  const config = {
    headers: {
      email: localStorage.getItem("player_email")
    }
  }

  // ADMIN Auth
  const [isAdminLoggedIn] = useState(
    () => localStorage.getItem('token') !== null
  );

  const p_btn = () => {
    setPause(!pause)
    document.body.style.overflow = pause ? "auto" : "hidden"
  }

  const [isLoggedIn] = useState(() => localStorage.getItem('player_email') !== null);

  const updateCoins = (newCoins) => {
    setCoins(newCoins);
  }

  const winOrLose = (winn, text, shared) => {
    setWin(winn);
    setWinText(text);
    document.body.style.overflow = win ? "auto" : "hidden";

    shared && setEnableAddMove(false)
  }

  const search = async (searchQuery) => {
    console.log('query', searchQuery);
    const postData = {
      search: searchQuery
    };

    try {
      const response = await axios.post(`https://api.sleepingwithwho.com/api/stories/search`, postData);
      // Handle successful post request
      console.log(response.data);
      setSearchResults(response.data);
      navigate("/stories/search");
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const share = () => {
    ref.current = ref.current + 1; //the hint on the modal uses the ref value
    if (enableAddMove) {
      setWin(false)
      setAddMoves(true)
    } else {
      setAddMoves(false)
    }
  }

  const close = () => {
    setWin(false);
    document.body.style.overflow = win ? "auto" : "hidden";
  }

  const algo = (id) => {
    const res = fetch(`https://api.sleepingwithwho.com/api/${window.$userid}/scenes/${id}`, config)
      .then((response) => response.json())
      .then((data) => {
        var mal = data.characters.filter((x) => x.gender === "male");
        var fem = data.characters.filter((x) => x.gender === "female");

        const lashers = [];
        let aCount = 0;
        let bCount = 0;

        while (lashers.length < 6) {
          const a = Math.floor(Math.random() * mal.length);
          const b = Math.floor(Math.random() * fem.length);

          // Check if a and b have appeared less than twice in the lashers array
          const aTotalCount = lashers.filter(l => l[0] === mal[a]).length;
          const bTotalCount = lashers.filter(l => l[1] === fem[b]).length;

          if (aTotalCount < 2 && bTotalCount < 2) {
            const lash = [mal[a], fem[b]];
            if (!lashers.some(arr => arr.includes(...lash))) {
              lashers.push(lash);

              // Update the counters for a and b
              aCount = aTotalCount + 1;
              bCount = bTotalCount + 1;
            }

          } else {
            // If a or b has already appeared twice, generate new random numbers
            aCount = aTotalCount;
            bCount = bTotalCount;
          }
        }
        return [lashers, data];
      });
    return res
  }

  return (
    <>
      {/* Game Pause Overlay */}
      {pause && <Modal resume={p_btn} />}
      {win && <WinModal text={winText} share={share} close={close} hint={ref} />}

      <Layout.Header coins={coins} />
      <Routes>
        {/* Guest Pages */}
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Pages.Login />}
        />
        <Route path="/" element={<Navigate to={"/scenes"} />} />
        <Route path="*" element={<Pages.PageNotFound />} />

        {/* Guarded Pagesss */}

        <Route path="/stories">
          <Route path="all" element={<Pages.Stories search={search} />} />
          <Route path=":id" element={<Pages.Story />} />
          <Route path="create" element={<Pages.CreateStory />} />
          <Route path="search" element={<Pages.SearchStories searchresults={searchResults} />} />
        </Route>

        <Route path="/scenes" element={<Pages.Scenes />} />

        <Route path="/scene">
          <Route path=":id" element={isLoggedIn ? <Pages.Scene /> : <Navigate to="/login" />} />
          <Route path=":id/showcase" element={isLoggedIn ? <Pages.Showcase algo={algo} pause={p_btn} func={updateCoins} winOrlose={winOrLose} addMoves={addMoves} /> : <Navigate to="/login" />} />
        </Route>

        {/* Admin Views */}
        <Route path="/admin">
          <Route path="login" element={isAdminLoggedIn ? <Navigate to="/admin/dashboard" /> : <Login />} />
          <Route path="dashboard" element={isAdminLoggedIn ? <Dashboard /> : <Navigate to="/admin/login" />} />
          <Route path="dashboard/:id" element={isAdminLoggedIn ? <AddChar /> : <Navigate to="/admin/login" />} />
          <Route path="stories" element={isAdminLoggedIn ? <AllStories /> : <Navigate to="/admin/login" />} />
          <Route path="pending_stories" element={isAdminLoggedIn ? <PendingStories /> : <Navigate to="/admin/login" />} />
          <Route path="stories/create" element={isAdminLoggedIn ? <AdminCreateStory /> : <Navigate to="/admin/login" />} />
          <Route path="stories/unapproved/:id" element={isAdminLoggedIn ? <Pages.AdminStory /> : <Navigate to="/admin/login" />} />
          <Route path="edit/story/:id" element={isAdminLoggedIn ? <AdminEditStory /> : <Navigate to="/admin/login" />} />
        </Route>
      </Routes>

      {/* Confetti */}
      <script src="https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js"></script>
    </>
  );
}

export default App;
