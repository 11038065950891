import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function AdminEditStory() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [story, setStory] = useState('');
  const [error, setError] = useState('');
  const [showStory, setShowStory] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function getStory() {
      try {
        const response = await axios.get(
          `https://api.sleepingwithwho.com/api/admin/unapproved/stories/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        );
        setShowStory(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    }
    getStory();
  }, [id]);

  useEffect(() => {
    if (showStory.length > 0) {
      setTitle(showStory[0].title);
      setDescription(showStory[0].description);
      setStory(showStory[0].story);
    }
  }, [showStory]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleEditorChange = (value) => {
    setStory(value);
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // text formatting options
    [{ align: [] }], // text alignment options
    // ['link', 'image', 'video'], // other options
  ];

  const handleSubmit = () => {
    const postData = {
      title: title,
      description: description,
      story: story,
    };

    axios
      .put(
        `https://api.sleepingwithwho.com/api/admin/stories/${id}`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        // Handle successful post request
        console.log(response.data);
        setError('');
        window.location = '/admin/pending_stories';
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        setError('An error occurred. Please try again later.');
      });
  };

  return (
    <>
      <div className="m-3">
        <h1>Create Your story</h1>
      </div>
      {error && <div>Error: {error}</div>}

      <div>
        <div className="m-3">
          <label>Title:</label>
          <br />
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            style={{ width: '100%' }}
            required
          />
          <br />

          <br />
          <label>Description:</label>
          <br />
          <input
            type="text"
            value={description}
            onChange={handleDescriptionChange}
            style={{ width: '100%' }}
            required
          />
          <br />

          <br />
          <label>Story:</label>
          <br />
          <ReactQuill
            theme="snow"
            value={story}
            onChange={handleEditorChange}
            modules={{ toolbar: toolbarOptions }}
            style={{ height: 200 }}
          />
          <br />

          <br />
          <br />
          <button onClick={handleSubmit}>Update</button>
        </div>
      </div>
    </>
  );
}

export default AdminEditStory;
