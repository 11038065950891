import { Link } from "react-router-dom"

const SceneCard = ({ id, name, img }) => {
    return (
        <Link to={'/scene/' + id}>
            {/* <div className="card" style={{backgroundImage: `url("http://localhost:8000/storage/scenes/${img}")`,backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}></div> */}
            <div className="card" style={{ backgroundImage: `url("https://api.sleepingwithwho.com/public/storage/scenes/${img}")`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
            <div><b><center>{name}</center></b></div>
        </Link>
    )
}

export default SceneCard