import { Link } from 'react-router-dom'
import Instruction from '../components/Instruction';
import Footer from '../components/Footer';
import { useState } from 'react';

import clickSound from "../assets/sounds/click.wav";

const audio = new Audio(clickSound);

function Home() {
    const [play, setPlay] = useState(false)

    const handle_play = () => {
        setPlay(!play);
        if (!play) {
            audio.play();
        }
    }

    return (
        <>
            <div className="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <main className='container'>
                <div className="text-center text-white">
                    <h2 className='text-success head-text'>
                        The Game Everyone is <br /> Talking About
                    </h2>
                    <p className="mt-3" style={color_3}>
                        <b>“Sleeping With Who”</b> is a game born out of the experience of its creator <br /> who while working at a police department,
                        witnessed his colleagues have numerous affairs with each other. <br />
                        After he was passed for promotion for a less qualified co-worker who was sleeping with their supervisor, he was forced out after he complained.
                    </p>
                    <div className='w-50 mx-auto'>
                        <Link to="/scenes">
                            <button className="cta cta-1" type='button' onClick={handle_play}>
                                <i className="bi bi-play-fill" style={color}></i> Play
                            </button>
                        </Link>
                        <button className="cta cta-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button" onClick={handle_play}>
                            <i className="bi bi-file-text-fill" style={color_2}></i> Instructions
                        </button>
                        <Link to="/stories/all">
                            <button className="cta cta-2 d-inline" type='button' onClick={handle_play}>
                                <i className="bi bi-chat-square-text-fill" style={color_2}></i> Sleeping With Who Stories
                            </button>
                        </Link>
                    </div>
                    {/* <Instruction /> */}
                    {/* Random character */}
                    <div className='rand_char'>
                        <img src={require(`../assets/full_body/mary-2.png`)} alt="..." />
                    </div>
                    <div className='rand_char_2'>
                        <img src={require(`../assets/full_body/sandowsky-2.png`)} alt="..." />
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}

const color = {
    color: '#6842FF',
}

const color_2 = {
    color: '#ff6c71'
}

const color_3 = {
    color: '#6842FF',
    letterSpacing: '1px',
    fontSize: '15px'
}

export default Home;