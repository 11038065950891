import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../css/createForm.css";
import Loader from "../../layout/Loader";
import { useParams } from "react-router-dom";
import Avatar from "../../components/Avatar";
import CharModal from "../../components/CharModal";

const AddChar = ({ onAdd }) => {
  const { id } = useParams();

  const [allChars, setAllChars] = useState([]);
  const [loader, setLoader] = useState(false);
  const [scenes_id, setScenes_id] = useState(id);
  const [editBtn, setEditBtn] = useState("Edit Scene");

  const [char_name, setChar_name] = useState("");
  const [about_char, setAbout_char] = useState("");
  const [char_img, setChar_img] = useState("");
  const [full_img, setFull_img] = useState("");
  const [gender, setGender] = useState("");
  const [description, setdescription] = useState("");
  const [bg_image, setBg_image] = useState("");
  const [name, setname] = useState("");
  const [modal, setModal] = useState(null);

  useEffect(() => {
    async function getChar() {
      // await axios(`http://localhost:8000/api/admin/${id}/dashboard/characters`,{
      // await axios(`http://localhost:8000/api/admin/dashboard/scenes/${id}`, {
      await axios(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res) => {
        // await axios(`https://api.sleepingwithwho.com/api/admin/${id}/dashboard/characters`).then((res) => {
        setname(res.data.name);
        setdescription(res.data.description);
        setAllChars(res.data.characters);
        console.log(name);
      });
    }
    getChar();
  }, [loader]);

  const handleClick = (params) => {
    setModal(params);
  };

  const handleClose = () => {
    setModal(null);
  };

  function submitChar(e) {
    e.preventDefault();

    setScenes_id(id);
    var scenes_id = id;
    if (!char_name || !about_char || !char_img || !full_img || !gender || !scenes_id) {
      alert("Fill out all fields");
    } else {
      setLoader(true);
      // const res = axios.post('https://api.sleepingwithwho.com/api/admin/dashboard/characters', {
      const res = axios.post("https://api.sleepingwithwho.com/api/admin/dashboard/characters",
        {
          char_name, about_char, char_img, full_img, gender, scenes_id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            alert("created successfully");
            setAbout_char("");
            setChar_img("");
            setChar_name("");
            setFull_img("");
            setGender("");
          }
          setLoader(false);
        })
        .catch((error) => {
          alert("error in creating scene");
          console.log(error);
          setLoader(false);
        });
    }
  }

  function editScene() {
    var scene = document.getElementById("scene-form");
    var char = document.getElementById("addCharacter");
    if (char.style.display === "block") {
      scene.style.display = "block";
      char.style.display = "none";
      setEditBtn("back");
    } else {
      scene.style.display = "none";
      char.style.display = "block";
      setEditBtn("Edit scene");
    }
  }

  function submitScene(e) {
    e.preventDefault();
    if (!name || !description || !bg_image) {
      alert("Fill out all fields");
    } else {
      setLoader(true);
      // const res = axios.put(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}`, {
      const res = axios
        .post(`https://api.sleepingwithwho.com/api/admin/dashboard/scenes/${id}/edit`,
          {
            name, description, bg_image,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert('updated success')
            window.location = "/admin/dashboard/"
          }
          setLoader(false)
          console.log(response);
        })
        .catch((error) => {
          alert("error in updating scene");
          console.log(error);
          setLoader(false);
          editScene();
        });
    }
  }

  function removeChar(scene_id) {
    setLoader(true);
    // const res = axios.delete(`http://localhost:8000/api/admin/dashboard/characters/${id}`,
    const res = axios.delete(`https://api.sleepingwithwho.com/api/admin/dashboard/characters/${scene_id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.data.status === 200) {
          alert("deleted successfully");
        }
        setLoader(false);
      })
      .catch((error) => {
        alert("error in deleting character");
        console.log(error);
        setLoader(false);
      });
    // alert(scene_id)
  }

  return (
    <div className="container dashboard mt-4 p-3">
      {loader ? (
        <Loader />
      ) : (
        <div>
          <form
            id="scene-form"
            onSubmit={submitScene}
            style={{ display: "none" }}
            encType="multipart/form-data"
            method="post"
          >
            <h4>Edit Scene</h4>
            <input
              type="text"
              name="scene_name"
              placeholder={name}
              className="form-control my-3"
              onChange={(e) => setname(e.target.value)}
              required
            />
            <textarea
              type="text"
              name="scene_description"
              placeholder={description}
              className="form-control my-3"
              onChange={(e) => setdescription(e.target.value)}
            ></textarea>
            <input
              type="file"
              className="form-control"
              name="char_img"
              onChange={(e) => setBg_image(e.target.files[0])}
              required
            />

            <br />
            <input
              type="submit"
              value="Update Scene"
              className="btn btn-primary"
            />
          </form>

          {/* {Char.map} */}
          {modal && (
            <>
              <div className="char-card" onClick={handleClose}>
                <img
                  //   src={`https://api.sleepingwithwho.com/storage/characters/${modal.full_img}`}
                  src={`https://api.sleepingwithwho.com/public/storage/characters/${modal.full_img}`}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h4>{modal.name}</h4>
                  <p className="card-text">{modal.about}</p>
                  <button className="btn" onClick={(e) => { removeChar(modal.id) }}>
                    <i className="bi bi-x-circle fs-1"></i>
                  </button>
                </div>
              </div>
            </>
          )}

          <div id="addCharacter" style={{ display: "block" }}>
            <div id="char">
              {allChars.length !== 0 &&
                allChars.map((char, index) => (
                  <div
                    className="d-inline"
                    key={char.id}
                    onClick={() => handleClick(char)}
                  >
                    <Avatar char={char} />
                  </div>
                ))}

              <form
                // <div className='avatar' id={index}>
                //     <img alt="..." src={`http://localhost:8000/storage/characters/${char.img}`} style={imgStyle} />
                //     {/* <img alt="..." src={`https://api.sleepingwithwho.com/public/storage/characters/${char.img}`} style={imgStyle} /> */}
                //     <i><center className='text-danger'>{char.char_name}</center></i>
                // </div>
                onSubmit={submitChar}
                encType="multipart/form-data"
                method="post"
              >
                <h4>Add Characters</h4>
                <input
                  type="text"
                  name="char_name"
                  placeholder="Dr Lash"
                  className="form-control my-3"
                  onChange={(e) => setChar_name(e.target.value)}
                  required
                />
                <textarea
                  type="text"
                  name="about_char"
                  placeholder="Dr lash the best lasher"
                  className="form-control my-3"
                  onChange={(e) => setAbout_char(e.target.value)}
                ></textarea>
                <input
                  type="file"
                  className="form-control"
                  name="char_img"
                  onChange={(e) => setChar_img(e.target.files[0])}
                  required
                />
                <input
                  type="file"
                  className="form-control"
                  name="full_img"
                  onChange={(e) => setFull_img(e.target.files[0])}
                  required
                />

                <br />
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  onChange={(e) => setGender(e.target.value)}
                />
                <i>Male</i>
                <br />
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  onChange={(e) => setGender(e.target.value)}
                />
                <i>Female</i>
                <br />

                <br />
                <input
                  type="submit"
                  value="Add Character"
                  className="btn border border-2"
                />
              </form>
            </div>
          </div>

          <div>
            <br />
            <hr />
            <center>
              <button className="btn btn-primary" onClick={editScene}>
                {editBtn}
              </button>
            </center>
          </div>
          <center><a href="/admin/dashboard">Back</a></center>
        </div>
      )}
    </div>
  );
};

const imgStyle = {
  objectFit: "cover",
  maxWidth: "100%",
  borderRadius: "100%",
};

export default AddChar;
