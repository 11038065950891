import React, { Component } from 'react'

class PageNotFound extends Component {
    state = {}
    render() {
        return (
            <main className='container text-center mt-5'>
                <h4>
                    page not found
                </h4>
            </main>
        );
    }
}

export default PageNotFound;