import { Link } from "react-router-dom"

const Button = (props) => {
  return (
    <Link to={props.link}>
      <button className="btn btn-sultry">
        {props.text}
      </button>
    </Link>
  )
}

export default Button