import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from 'react-cookies'
import "../css/Scenes.css";

import Footer from "../components/Footer";
import SceneCard from "../components/SceneCard";
import LockedCard from "../components/LockedCard";
import Loader from "../layout/Loader";
import ContactUs from "../components/ContactUs";
import Instruction from "../components/Instruction";
import Navbar from "../components/Navbar";
import AboutSww from "../components/AboutSww";
import VerifyEmail from "../components/VerifyEmail";

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ReCAPTCHA from "react-google-recaptcha";

const Scenes = () => {
  const [scenes, setScenes] = useState(null);
  const [unlockedScenes, setUnlockedScenes] = useState(null);
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    async function getScenes() {
      try {
        if (localStorage.getItem("player_email")) {
          const res = await axios(`https://api.sleepingwithwho.com/api/${window.$userid}/scenes`);
          setUnlockedScenes(res.data.user_scenes);
          setScenes(res.data.locked_scenes)

          var scenesLength = Object.keys(res.data.user_scenes).length;
          if (scenesLength > Cookies.load('scenes')) {
            alert('new scene unlocked')
          };
          Cookies.save('scenes', scenesLength, { path: '/' });

        } else {
          const res = await axios(`https://api.sleepingwithwho.com/api/scenes`);
          setScenes(res.data);
        }
      } catch (error) {
        localStorage.clear();
      }
    }
    getScenes();
  }, []);

  // Submit User email
  const onSubmit = async (e) => {
    const data = { email: email };
    e.preventDefault();

    setLoading(true);
    axios.post('https://api.sleepingwithwho.com/api/login', data)
      .then(({ data }) => {
        console.log(data);
        // if (!data?.user?.email_verified_at) {
        //   setEmail(data.user.email)
        //   const modal = new bootstrap.Modal(document.getElementById('verify-email'));
        //   modal.show();
        //   return;
        // }

        localStorage.setItem('player_email', data.user.email);
        localStorage.setItem('user_id', data.user.id);
        localStorage.setItem('coins', data.coins);

        window.location.reload();
      }).catch((error) => {
        console.log(error);
        const modal = new bootstrap.Modal(document.getElementById('verify-email'));
        modal.show();
        return;
      });
  }

  const recaptcha = (value) => {
    console.log("Captcha value:", value);
    setIsCaptchaVerified(true);
  }

  return (
    <div className="container mt-4">
      <h2 className="text-success">Scenes</h2>
      <p style={color}>
        Follow us on {" "}
        <a href="https://www.facebook.com/groups/sleepingwithwho/" target="_blank">Facebook</a>,{" "}
        <a href="https://twitter.com/sleepingwitwho" target="_blank">Twitter</a>, and {" "}
        <a href="https://t.me/sleepingwithwho" target="_blank">Telegram</a>.
      </p>


      <button className="btn btn-sultry" style={{ fontSize: "11px" }} data-bs-toggle="modal" data-bs-target="#instructions" type="button">
        <i className="bi bi-file-text-fill"></i> <span>Instructions</span>
      </button>

      {!localStorage.getItem("player_email") &&
        (<>
          <button type="button" className="ms-2 btn btn-sultry" style={{ fontSize: "11px" }} data-bs-toggle="modal" data-bs-target="#exampleModal">
            Tap Here to Play
          </button>

          {/* <!-- Modal --> */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title fs-5 text-success" id="exampleModalLabel">Login to play</h2>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={onSubmit}>
                    <input type="email" name="email" className="w-100 rounded-3 form-control border-2 border-dark py-3" id="email-field" placeholder="casanova@xyz.com" onChange={(e) => setEmail(e.target.value)} required />
                    <center>
                      <ReCAPTCHA
                        sitekey="6Lcaci0pAAAAAPOJm6ZX8fTbMkeTFx3U1aF5jZXb"
                        onChange={recaptcha} className="mt-2 w-100"
                      />
                    </center>
                    <button type="submit" disabled={!isCaptchaVerified} className="btn btn-sultry fs-4 p-2 mt-4 w-100" data-bs-dismiss="modal" aria-label="Close">
                      Play
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>)
      }

      {loading ? (<Loader />) :
        (<div id="box">
          {/* Map for open scenes */}
          {unlockedScenes && unlockedScenes.map((scene) => (<SceneCard key={scene.id} id={scene.id} name={scene.name} img={scene.bg_image} />))}
          {/* Map for locked scenes */}
          {scenes ? scenes.map((scene) => <LockedCard key={scene.id} id={scene.id} name={scene.name} img={scene.bg_image} />) : <Loader />}
        </div>)
      }
      <Navbar />
      <Instruction />
      <AboutSww />
      <ContactUs />
      <Footer />
      <VerifyEmail email={email} />
    </div>
  );
};

const color = {
  color: "#E40000"
}
export default Scenes;
