import { useEffect, useState } from "react"

const Coin = (props) => {
    const [coins, setCoins] = useState(props.coins)

    useEffect(() => {
        setCoins(props.coins)
        document.getElementById('coin').classList.add('btn-coin')
        setTimeout(() => {
            document.getElementById('coin').classList.remove('btn-coin')
        }, 5000)
    }, [props.coins])


    return (
        <button className="btn btn-coin py-0" title={`You have ${coins} coins`} id="coin">
            <img src={require('../assets/coins.png')} width="20px" style={coin_style} /> {coins}
        </button>
    )
}

export default Coin

const coin_style = {
    transform: "scale(1.1, 1.1)",
    margin: "0 5px"
}

